import React from 'react'
import {
  AlternativeHero,
  Breadcrumbs,
  Page,
  Products,
} from '@components'

const meta = {
  title: 'Ostalo - Protupožarno brtvljenje | Protupožarna zaštita',
  description: '',
  image: require('@assets/images/protupozarno-brtvljenje.jpg'),
}

const Category = () => (
  <Page
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/proizvodi/', name: 'Proizvodi'},
        { link: `/proizvodi/protupozarno-brtvljenje/`, name: 'Protupožarno brtvljenje'},
        { link: `/proizvodi/protupozarno-brtvljenje/ostalo/`, name: 'Ostalo'},
      ]}
    />
    <AlternativeHero
      description=""
      slim
      title="Ostalo"
      subtitle="Proizvodi - Protupožarno brtvljenje"
    />
    <Products activeCategory="ostalo" />
  </Page>
)

export default Category